@import "../../assets/admin/styles/color.scss";

html, body {
    height: 100%;

    :global(#root) {
        height: 100%;
    }
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .image {
        height: 285px;
        width: 270px;
        background-repeat: no-repeat;
        background-position: center;
    }

    span {
        position: absolute;
        font-size: 350px;
        color: #e21744;
    }
}
        